<template>
    <div style="margin-top: 50px" class="mb-15">
        <v-overlay :absolute="true" :value="submitting">
            <v-progress-circular indeterminate color="primary" class="mr-5"></v-progress-circular>
            <span>Submitting. Please wait...</span>
        </v-overlay>
        <div class="container-bg" style="margin-top: 5% !important; width: 700px; margin: auto">
            <div class="container-fg">
                <div style="display: flex; flex-direction: row">
                    <img style="width: 130px; margin-bottom: 1%" src="../../assets/logos/getac-logo.svg" />
                    <div class="text-h7 nav-font pl-3" style="margin-top: 1.3%">PAY QUOTE</div>
                </div>
                <v-divider style="margin-bottom: 10px; border-color: gray" />
                <div style="display: flex; flex-direction: row">
                    <div
                        v-if="!loading && quoteAccess"
                        class="quote-info pa-10"
                        style="display: block; margin: auto; width: 100%; background-color: rgb(56, 56, 56)"
                    >
                        <v-alert
                            v-if="quote.details.payment && quote.details.payment.status === 'COMPLETED'"
                            outlined
                            type="success"
                            text
                        >
                            PAID
                        </v-alert>
                        <v-alert v-else-if="quote.status === 'Cancelled'" outlined type="error" text>
                            CANCELED
                        </v-alert>
                        <v-row>
                            <v-col cols="6" class="text-start">
                                <v-list-item-subtitle>{{ quote.shipping.contact.companyName }} </v-list-item-subtitle>
                                <v-list-item-subtitle>{{
                                    quote.shipping.contact.firstName + ' ' + quote.shipping.contact.lastName
                                }}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{ quote.shipping.contact.emailAddress }}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{ quote.shipping.contact.phoneNumber }}</v-list-item-subtitle>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <v-list-item-subtitle>{{ quote.shipping.address.line1 }}</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="quote.shipping.address.line2">{{
                                    quote.shipping.address.line2
                                }}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{
                                    quote.shipping.address.city + ', ' + quote.shipping.address.state
                                }}</v-list-item-subtitle>
                                <v-list-item-subtitle>{{
                                    quote.shipping.address.country + ', ' + quote.shipping.address.postalCode
                                }}</v-list-item-subtitle>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <base-info-card title="Quote Number" color="primary">
                                    <v-list-item-subtitle> {{ quote.uid }}</v-list-item-subtitle>
                                </base-info-card>
                            </v-col>
                            <v-col cols="4" class="text-right">
                                <base-info-card title="Serial Number" color="primary">
                                    <v-list-item-subtitle> {{ quote.reference }}</v-list-item-subtitle>
                                </base-info-card>
                            </v-col>
                            <v-col cols="4" class="text-right">
                                <base-info-card title="Service Type" color="primary">
                                    <v-list-item-subtitle> {{ quote.service }}</v-list-item-subtitle>
                                </base-info-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" class="text-right">
                                <base-info-card title="Quote Items" color="primary"> </base-info-card>
                            </v-col>
                            <v-simple-table dark style="width: 100%; background-color: transparent">
                                <template v-slot>
                                    <thead>
                                        <tr>
                                            <th class="text-left"> Name </th>
                                            <th class="text-left"> Description </th>
                                            <th class="text-left"> Quantity </th>
                                            <th class="text-left"> Total </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in quote.items" :key="index">
                                            <td>{{ item.uid }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.quantity }}</td>
                                            <td>{{ item.total }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-row>
                        <br />
                        <base-info-card title="Quote Remarks" color="primary">
                            <v-textarea outlined readonly full-width height="300px" :value="quote.quoteRemark" dark>
                                {{ quote.quoteRemark }}</v-textarea
                            >
                        </base-info-card>
                        <br />
                        <v-row class="mt-5">
                            <v-col class="text-right">
                                <base-info-card title="Subtotal" color="primary">
                                    <v-list-item-subtitle> ${{ quote.subtotal.toFixed(2) }}</v-list-item-subtitle>
                                </base-info-card>
                                <base-info-card title="Freight" color="primary">
                                    <v-list-item-subtitle> ${{ quote.amount.freight }}</v-list-item-subtitle>
                                </base-info-card>
                                <base-info-card title="Total Amount Due" color="primary">
                                    <v-list-item-subtitle> ${{ quote.total }}</v-list-item-subtitle>
                                </base-info-card>
                            </v-col>
                        </v-row>
                        <v-row v-if="validate('PO')" class="mt-5">
                            <v-col class="text-center">
                                <paypal-button
                                    :valid="true"
                                    :create="proceedPayment"
                                    :approve="confirmPayment"
                                    :validate="() => validate('PO')"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="validate('PayPal')" class="mt-5">
                            <span>
                                <v-list-item>
                                    <v-list-item-content
                                        :data-cy="`info-card-key-value-payment-method`"
                                        class="align-end text-body-2 field-values"
                                    >
                                        <base-info-card :title="poTitle" style="color: white" color="primary">
                                            <v-text-field
                                                v-model="poNumber"
                                                outlined
                                                dark
                                                :label="poLabel"
                                                dense
                                            ></v-text-field>
                                        </base-info-card>
                                        <base-info-card
                                            v-if="isMcElroy"
                                            style="color: white"
                                            :title="releaseTitle"
                                            color="primary"
                                        >
                                            <v-text-field
                                                v-model="releaseNumber"
                                                outlined
                                                dark
                                                :label="releaseLabel"
                                                dense
                                            ></v-text-field>
                                        </base-info-card>
                                    </v-list-item-content>
                                </v-list-item>
                            </span>
                            <span>
                                <v-list-item style="margin-top: -35px">
                                    <v-list-item-content
                                        :data-cy="`info-card-key-value-payment-method`"
                                        class="align-end text-body-2 field-values"
                                    >
                                        <po-uploader :dark-mode="true" @fileId="getFileName($event)"></po-uploader>
                                    </v-list-item-content>
                                </v-list-item>
                            </span>
                            <v-row class="mt-5">
                                <v-col class="text-center">
                                    <v-btn
                                        color="primary"
                                        outlined
                                        class="text-center"
                                        :disabled="unableToSumbitPO"
                                        style="margin-bottom: 25%; margin-left: 10px"
                                        @click="proceedPO"
                                        >Submit PO</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-row>
                    </div>
                </div>
            </div>
        </div>
        <payment-dialog
            ref="payment"
            :url="paymentUrl"
            :rma-number="quoteid"
            :payment-type="'quote'"
            @canceled="paymentCanceled"
            @success="confirmPayment"
        >
        </payment-dialog>
        <v-snackbar v-model="snackbar" color="error">
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" color="white" @click="snackbar = false"> Close </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import gql from 'graphql-tag'
import PaymentDialog from '@/components/payment_dialog/payment_dialog.vue'
import PoUploader from '@/components/po_uploader/po_uploader.vue'
import PaypalButton from '@/components/paypal_button/paypal_button.vue'

export default {
    name: 'PayQuote',
    components: { PaymentDialog, PoUploader, PaypalButton },
    props: {
        quoteid: {
            type: String,
            required: false,
            default: ``
        },
        reference: {
            type: String,
            required: false,
            default: ``
        }
    },
    data() {
        return {
            quoteReady: false,
            quote: null,
            paypalValid: false,
            paymentUrl: '',
            submitting: false,
            quoteAccess: false,
            poUpload: '',
            poNumber: '',
            poSubmitted: false,
            loading: true,
            snackbar: false,
            snackbarMessage: '',
            poTitle: 'Enter PO Number',
            poLabel: 'PO #',
            releaseTitle: 'Enter Release Number',
            releaseLabel: 'Release # (Ex: 0116)',
            releaseNumber: ''
        }
    },
    computed: {
        isMcElroy() {
            return (this.$auth?.user?.email || '').endsWith('@mcelroy.com')
        },
        unableToSumbitPO() {
            const regex = /^\d{4,5}$/
            return (
                !this.poNumber ||
                !this.poUpload ||
                this.poSubmitted ||
                (this.isMcElroy && !regex.test(this.releaseNumber))
            )
        }
    },
    watch: {
        quoteid: {
            immediate: true,
            handler() {
                this.loading = true
                this.$apollo
                    .query({
                        query: gql`
                            query GetSingleQuote($uid: String!) {
                                getSingleQuote(uid: $uid) {
                                    uid
                                    customerSaved
                                    service
                                    reference
                                    externalResource
                                    status
                                    paymentMethod
                                    shipping {
                                        address {
                                            line1
                                            line2
                                            city
                                            state
                                            country
                                            postalCode
                                            international
                                            apo
                                        }
                                        contact {
                                            emailAddress
                                            phoneNumber
                                            firstName
                                            lastName
                                            companyName
                                            fullName
                                        }
                                    }
                                    billing {
                                        address {
                                            line1
                                            line2
                                            city
                                            state
                                            country
                                            postalCode
                                            international
                                            apo
                                        }
                                        contact {
                                            emailAddress
                                            phoneNumber
                                            firstName
                                            lastName
                                            companyName
                                            fullName
                                        }
                                    }
                                    items {
                                        uid
                                        name
                                        price
                                        quantity
                                        tax
                                        total
                                    }
                                    amount {
                                        freight
                                        handling
                                        discount
                                        tax
                                    }
                                    redirects {
                                        completed
                                        canceled
                                    }
                                    details {
                                        payment {
                                            uid
                                            source
                                            url
                                            status
                                            transaction
                                            adminStatus
                                            possibleStatusList
                                        }
                                        tax {
                                            uid
                                            source
                                            status
                                        }
                                    }
                                    createdAt
                                    updatedAt
                                    quoteRemark
                                    customerPo {
                                        poNumber
                                        poFileId
                                    }
                                    createdBy
                                    subtotal
                                    total
                                }
                            }
                        `,
                        variables: {
                            uid: this.quoteid
                        }
                    })
                    .then((data) => {
                        this.quote = data.data.getSingleQuote
                        if (this.quote.reference === this.reference) {
                            this.quoteAccess = true
                        }
                    })
                    .finally(() => {
                        this.loading = false
                        this.quoteReady = true
                    })
            }
        }
    },
    methods: {
        validate(wrongType) {
            let allowed = false
            if (this.quote.details.payment) {
                allowed = this.quote.details.payment.status !== 'COMPLETED' && this.quote.status !== 'Cancelled'
            } else if (this.quote.details.payment === null) {
                allowed = wrongType !== 'PO' ? this.quote.customerPo.poFileId === null : true
            }
            return this.quote && this.quote.paymentMethod !== wrongType && allowed
        },
        actionReceived(message) {
            this.snackbar = true
            this.snackbarMessage = message
        },
        getFileName(file) {
            this.poUpload = file.file
        },
        paymentCanceled() {
            this.$router.push({ name: 'service-quote-canceled' })
        },
        confirmPayment() {
            this.submitting = true
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation ConfirmServiceQuotePayment($quoteId: String!) {
                            confirmServiceQuotePayment(quoteId: $quoteId) {
                                payment {
                                    uid
                                    status
                                    adminStatus
                                    transaction
                                    url
                                    source
                                }
                            }
                        }
                    `,
                    variables: {
                        quoteId: this.quoteid
                    }
                })
                .then(() => {
                    this.$router.push({ name: 'service-quote-success' })
                })
                .catch((errors) => {
                    this.actionReceived(errors)
                })
        },
        confirmProjectPayment() {
            this.submitting = true
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation ConfirmProjectPayment($uid: String!) {
                            confirmProjectPayment(uid: $uid) {
                                complete
                                success
                                error
                                uid
                            }
                        }
                    `,
                    variables: {
                        uid: this.quoteid
                    }
                })
                .then((data) => {
                    console.log(data)
                })
        },
        proceedPO() {
            this.submitting = true
            this.poSubmitted = true
            const po = this.isMcElroy ? `${this.poNumber}-${this.releaseNumber}` : this.poNumber
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation AuthorizePOQuote(
                            $approved: Boolean!
                            $quoteId: String!
                            $poNumber: String
                            $poFileId: String
                        ) {
                            authorizePOQuote(
                                approved: $approved
                                quoteId: $quoteId
                                poNumber: $poNumber
                                poFileId: $poFileId
                            )
                        }
                    `,
                    variables: {
                        quoteId: this.quoteid,
                        poNumber: po,
                        poFileId: this.poUpload,
                        approved: true
                    }
                })
                .then((data) => {
                    if (data.data.authorizePOQuote === 'ok') {
                        this.$router.push({ name: 'service-quote-success' })
                    } else {
                        this.poSubmitted = false
                    }
                })
        },
        proceedPayment() {
            if (this.quote.total > 2499.99) {
                this.snackbar = true
                this.snackbarMessage =
                    'Cannot proceed with PayPal amount over $2,499.99. Please contact technical support'
            } else {
                return this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation Mutation($quoteId: String!) {
                                startServiceQuotePayment(quoteId: $quoteId) {
                                    uid
                                    url
                                }
                            }
                        `,
                        variables: {
                            quoteId: this.quoteid
                        }
                    })
                    .then((data) => {
                        const params = new URLSearchParams(data.data.startServiceQuotePayment.url.split('?')[1])
                        if (!params.get('token')) {
                            throw new Error('No PayPal token found')
                        }
                        this.paymentUrl = data.data.startServiceQuotePayment.url
                        return params.get('token')
                    })
                    .catch((e) => {
                        this.actionReceived(e)
                    })
            }
        }
    }
}
</script>
